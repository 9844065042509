<template>
	<div></div>
</template>

<script>
import { ref, watch } from '@vue/composition-api'

import { useRouter } from '@core/utils'
import { successSwal, warningSwal } from '@/plugins/swalMixin'

import UserService from '@/services/UserService'

export default {
	components: {},
	setup() {
		const { router, route } = useRouter()
		const kakaoRedirectUrl = ref(`${window.location.origin}/auth/kakao-link`)

		const linkSigninKakao = async payload => {
			try {
				await UserService.linkSigninKakao(payload)
				const confirm = await successSwal('카카오톡 계정과 연결되었습니다')
				if (confirm.isConfirmed) {
					router.push({ name: 'mypage-setting' })
				}
			} catch (e) {
				const confirm = await warningSwal(
					e.response.status === 400
						? e.response.data.message
						: '카카오톡 계정을 연결하는데 문제가 발생되었습니다',
				)

				if (confirm.isConfirmed) {
					router.push({ name: 'mypage-setting' })
				}
			}
		}

		watch(
			() => route.value.query.code,
			currentValue => {
				if (currentValue) {
					linkSigninKakao({
						code: currentValue,
						redirectUrl: kakaoRedirectUrl.value,
					})
				} else {
					router.go('mypage-dashboard')
				}
			},
			{
				immediate: true,
			},
		)
	},
}
</script>
<style lang="scss" scoped></style>
